.about {
    display: flex;
    padding-top: 20vh;
    justify-content: center;
    height: 90vh;
    background: linear-gradient(
      90deg,
      rgba(18, 19, 20, 1) 0%,
      rgba(34, 38, 43, 1) 59%,
      rgba(49, 54, 59, 1) 100%
    );
    position: relative;
    color: white;
  }
  
  .about__text {
    display: flex;
    flex-direction: column;
  }
  
  .about__body {
    font-family: "Fira Code", monospace;
    max-width: 50vw;
    padding-right: 50px;
  }
  
  .about__headshot {
    border-radius: 50%;
    border: 5px solid var(--purple);
    box-shadow: var(--purple) 0px 20px 30px -10px;
  }
  
  .about__specialText {
    color: var(--purple);
  }
  
  .about__externLink {
    color: var(--purple);
  }
  
  @media only screen and (max-width: 768px) {
    .about {
      padding-top: 10vh;
      flex-direction: column;
      align-items: center;
    }
  
    .about__heading {
      font-size: 50px;
    }
  
    .about__text {
      text-align: center;
      font-size: 14px;
    }
  
    .about__body {
      padding-right: 0;
      max-width: 60vw;
      padding-bottom: 15px;
      max-height: fit-content;
    }
  
    .about__headshot {
      display: none;
    }
  }