.lightWave {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
  }
  
  .lightWave svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 82px;
  }
  
  .lightWave .shape-fill {
    /* fill: #b8c6db; */
    fill: url("#light");
  }
  @media only screen and (max-width: 768px) {
    .lightWave{
      visibility: hidden;
    }
    }