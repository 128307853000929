.resumeButton {
    color: var(--purple);
    border: 2px solid var(--purple);
    padding: 5px 10px;
    border-radius: 10px;
  }
  
  .resumeButton:hover {
    color: white;
  }
  
  @media only screen and (max-width: 768px) {
    .resumeButton {
      margin: 20px;
    }
  }