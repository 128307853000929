.project {
    height: 85vh;
    position: relative;
    padding-left: 11vw;
    padding-top: 10vh;
  }
  
  .project__main {
    display: flex;
    align-items: center;
    padding-top: 5vh;
  }
  
  .project__image {
    border-radius: 15px;
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
  }
  
  .project__right {
    display: flex;
    flex-direction: column;
    margin-left: 50px;
   /* text-align: right;*/
    padding-bottom: 5vh;
    padding-right: 3vh;
    /* align-items: flex-end; */
  }
  
  .project__title {
    font-family: "Oswald", sans-serif;
    font-size: 40px;
    margin-bottom: 0.5rem;
    color: var(--dark-gray);
  }
  
  .project__body {
    font-family: "Roboto", sans-serif;
    
  }
  
  .project__description {
    max-width: 30vw;
    margin-bottom: 0.5rem;
  }
  
  .project__specialText {
    color: var(--purple);
  }
  
  .project__tech {
    color: var(--light-gray);
    font-size: 14px;
    margin-bottom: 0.5rem;
  }
  
  .project__sourceCode {
    display: flex;
    align-items: center;
    color: var(--purple);
    justify-content: flex-end;
    transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  }
  
  .project__sourceCodeText {
    font-family: "Oswald", sans-serif;
  }
  
  .project__sourceCode:hover {
    color: var(--light-gray);
    transform: scale(1.1);
    transform-origin: right;
  }
  
  .project__githubLogo {
    padding-right: 5px;
  }
  
  .light {
    background-color: #b8c6db;
    background-image: linear-gradient(315deg, #cbd3e6 0%, #f5f7fa 74%);
  }
  
  @media only screen and (max-width: 768px) {
    .light {
      background-image: linear-gradient(315deg, #b8c6db 0%, #f5f7fa 74%);
    }
  
    .project__specialMobileImage {
      display: none;
    }
    
    .project {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-left: 0;
      /* margin-bottom: 50vh; */
    }
  
    .project__title {
      text-align: center;
    }
  
    .project__main {
      flex-direction: column;
      padding-top: 0;
    }
  
    .project__right {
      margin-left: 0;
      align-items: center;
    }
  
    .project__description {
      max-width: 60vw;
      text-align: center;
    }
  
    .project__tech {
      text-align: center;
    }
  
    .project__sourceCode {
      justify-content: center;
    }
  
    .project__image {
      margin-bottom: 15px;
      width: 80%;
      padding-right: 10vh;
    }
  
    .project__specialMobileImage {
      width: 20%;
    }
  
    .project__sourceCode:hover {
      transform-origin: left;
    }
  }