.menuIcon__icon {
    display: none;
  }
  
  @media only screen and (max-width: 768px) {
    .menuIcon__icon {
      transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
      cursor: pointer;
      display: block;
    }
  
    .menuIcon__icon:hover {
      color: var(--purple);
      transform: scale(1.1);
    }
  }