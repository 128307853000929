.Nav {
    grid-area: nav;
    display: grid;
    grid-template-columns: repeat(4, auto);
    align-items: center;
    justify-items: center;
  }
  @media only screen and (max-width: 768px) {
    .Nav {
      transition: all 1s ease-out;
      clip-path: circle(0px at 90% -10%);
      -webkit-clip-path: circle(0px at 90% -10%);
      position: fixed;
      height: 100vh;
      width: 100vw;
      display: flex;
      flex-direction: column;
      justify-content: center;
      z-index: -1;
      background: linear-gradient(
        90deg,
        rgba(18, 19, 20, 1) 0%,
        rgba(34, 38, 43, 1) 59%,
        rgba(49, 54, 59, 1) 100%
      );
    }
    .active {
      clip-path: circle(1300px at 90% -10%);
      -webkit-clip-path: circle(1300px at 90% -10%);
      top: 0;
    }
  }