.socialMediaIcons {
    display: flex;
    align-items: center;
  }
  
  .hr {
    width: 35vw;
    height: 2px;
    background-color: var(--purple);
    border: none;
    margin: 20px auto;
  }
  
  .footer__icon {
    color: #5b5b5b;
    transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
    padding: 0 15px;
  }
  
  .footer__icon:hover {
    color: var(--purple);
    transform: scale(1.1);
  }
  
  .socialMediaIcons__rightHr {
    margin-left: 20px;
  }
  
  .socialMediaIcons__leftHr {
    margin-right: 20px;
  }